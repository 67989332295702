<template>
  <div class="p-3 d-flex flex-wrap w-100">
    <div class="">
      <router-link
        :to="{
          name: 'ProductDetail',
          params: { product: product.url.replace('product-', '') },
        }"
      >
        <img
          class="img-product-return-order"
          :src="product.producto_imagen"
          alt=""
        />
      </router-link>
    </div>
    <div class="data-product-return-order">
      <p class="title-product-return-order">
        {{ product.titulo.toLowerCase().replace(/^\w/, d => d.toUpperCase()) }}
      </p>
      <span class="detail-product-return-order"
        >SKU: {{ product.producto_sku }} <br />
        Talla: {{ product.talla }} <br />
        Color:
        {{ product.color.toLowerCase().replace(/^\w/, d => d.toUpperCase()) }}
        <br />
      </span>
      <span
        v-if="product.facturado == 0"
        style="padding: .1rem .3rem; border: 1px solid tomato; color: tomato; border-radius: 3px;"
        >Cancelado</span
      >
      <p v-else class="price-product-return-order">
        ${{ new Intl.NumberFormat('de-DE').format(product.precio) }}
      </p>
    </div>
    <div class="mt-3">
      <span
        v-if="product.devolucion"
        style="padding: .1rem .3rem; background-color: #C8D7E5; border-radius: 3px; font-family: 'PT Sans Bold', sans-serif; margin-bottom: .3rem; margin-right: 1rem"
        >Devolución</span
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    product: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
}
</script>
