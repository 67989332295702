<template>
  <div class="follow-order">
    <header-page-derek :title="'Seguimiento pedido'" :skeleton="false" />
    <div class="container-follow-order container-max-width">
      <div v-if="nextStep == 1">
        <div class="consult-order">
          <div class="form-consult-order">
            <div class="form-devoluciones pt-5 text-center">
              <div class="header-title-follow-order">
                <p style="font-size: 18px"><b>Seguimiento pedido</b></p>
                <p class="px-5 mb-4">
                  Debes proporcionarnos tu dirección de correo electrónico y tu
                  número de pedido para realizar el seguimiento.
                </p>
              </div>
              <div class="position-relative mx-auto" style="max-width: 320px">
                <input
                  id="email"
                  v-model="correo"
                  class="form-devoluciones-input"
                  placeholder="Correo electrónico"
                  type="email"
                />
                <span v-if="correo" class="newlatter-error-input"
                  >Correo electrónico</span
                >
              </div>
              <div class="input-info-devolucion">
                <div class="position-relative mx-auto" style="max-width: 320px">
                  <input
                    v-model="pedido_id"
                    class="form-devoluciones-input"
                    type="number"
                    placeholder="Número de pedido"
                  />
                  <span v-if="pedido_id" class="newlatter-error-input"
                    >Número de pedido</span
                  >
                </div>
                <img
                  id="devoluciones-info"
                  class="icon-info-devolucion"
                  src="@/assets/icons/icon-info.svg"
                  alt=""
                />
                <b-popover target="devoluciones-info" triggers="hover">
                  <div>
                    <p class="mb-1"><strong>El número de pedido</strong></p>
                    <span>
                      Podrás encontrar tu número de pedido en el correo
                      electrónico de confirmación de pedido. <br />
                      <br />
                      ¿No has recibido el correo electrónico de confirmación de
                      pedido? Revisa tu carpeta de correo electrónico no
                      deseado, tal vez esté allí. Si tu cuenta de correo
                      electrónico es de gmail, revisa la carpeta de
                      promociones.<br />
                      <br />
                      Si hace poco que has realizado el pedido, ten en cuenta
                      que aún tardaremos unos 5 minutos en procesarlo.
                    </span>
                    <img src="" alt="" />
                  </div>
                </b-popover>
                <div class="mb-3">
                  <span v-if="errorOrder" class="msg-error-input">{{
                    msgValidateError
                  }}</span>
                </div>
              </div>
              <button
                class="form-devoluciones-button btn-primary"
                type=""
                @click="getDataOrder()"
              >
                <div v-if="!loading">continuar</div>
                <div v-else class="d-flex justify-content-center">
                  Cargando...
                  <div class="loader" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <follow-up-order v-else />
      <footer-returns />
    </div>
  </div>
</template>
<script>
import FollowUpOrder from '@/components/FollowOrder/FollowUpOrder'
import HeaderPageDerek from '@/components/Templates/HeaderPageDerek'
import FooterReturns from '@/components/Returns/FooterReturns'

import { mapActions, mapState } from 'vuex'
export default {
  components: { FollowUpOrder, HeaderPageDerek, FooterReturns },
  data() {
    return {
      nextStep: 1,
      pedido_id: '',
      correo: '',
      msgValidateError: '',
      loading: false,
    }
  },
  computed: {
    ...mapState('user', ['dataOrder', 'errorOrder', 'msgErrorOrder']),
    /* eslint-disable */
    validateEmail: function() {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.correo)
    },
  },
  mounted() {
    // this.$gtag.pageview(this.$route.fullPath)
    window.scrollTo(0, 0)
  },
  methods: {
    ...mapActions('user', ['getInfoOrder']),
    async getDataOrder() {
      this.msgValidateError = ''
      if (this.validateEmail && this.pedido_id) {
        this.loading = true
        await this.getInfoOrder({
          email: this.correo,
          pedido_id: this.pedido_id,
        }).then(() => {
          if (!this.errorOrder) {
            this.loading = false
            this.nextStep = 2
          } else {
            this.loading = false
            this.msgValidateError = this.msgErrorOrder
          }
        })
      } else {
        this.msgValidateError = 'Todos los campos son requeridos'
      }
    },
  },
}
</script>
